const etapas = [
  {
    path: '/etapas',
    name: 'etapas.list',
    meta: { title: 'Etapas' },
    component: () => import('@/views/etapas/EtapasList.vue'),
    meta: { title: 'Etapas' }
  },
]

export default etapas
