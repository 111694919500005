const concorrentes = [
  {
    path: '/concorrentes',
    name: 'concorrentes.list',
    meta: { title: 'Concorrentes' },
    component: () => import('@/views/concorrentes/ConcorrentesList.vue'),
  },
]

export default concorrentes
