const areas = [
  {
    path: '/areas',
    name: 'areas.list',
    meta: { title: 'Áreas' },
    component: () => import('@/views/areas/AreasList.vue'),
  },
]

export default areas
