const sysVisualizacoes = [
  {
    path: '/visualizacoes',
    name: 'visualizacoes.list',
    meta: { title: 'Visualizações' },
    component: () => import('@/views/sysvisualizacoes/SysVisualizacoesList.vue'),
  },
]

export default sysVisualizacoes
