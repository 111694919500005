const macros = [
  {
    path: '/macros',
    name: 'macros.list',
    meta: { title: 'Macros' },
    component: () => import('@/views/macros/MacrosList.vue'),
  },
]

export default macros
