const erpGtinFotos = [
  {
    path: '/erp-gtin-fotos',
    name: 'erp-gtin-fotos.list',
    meta: { title: 'ERP | Fotos' },
    component: () => import('@/views/erpgtinfotos/ErpGtinFotosList.vue'),
  },
]

export default erpGtinFotos
