const sprints = [
  {
    path: '/sprints',
    name: 'sprints.list',
    meta: { title: 'Sprints' },
    component: () => import('@/views/sprints/SprintsList.vue'),
  },
]

export default sprints
