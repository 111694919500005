import Vue from 'vue'
import moment from "moment/moment";

Vue.filter('maskHMin', function (minutes) {

        const momentObj = moment.duration(minutes, 'minutes');
        const days = momentObj.days();
        const hours = momentObj.hours();
        const minutesPart = momentObj.minutes();

        let retorno = '';
        if (days > 0) {
            retorno = `${days}d `;
        }
        if (hours > 0) {
            retorno = retorno + `${hours}h ${minutesPart.toString().padStart(2, '0')}min`;
        } else {
            retorno = retorno + `${minutesPart.toString().padStart(2, '0')}min`;
        }
        return retorno;
    }
)
