const clientes = [
  {
    path: '/clientes',
    name: 'clientes.list',
    meta: { title: 'Clientes' },
    component: () => import('@/views/clientes/ClientesList.vue'),
  },
  {
    path: '/clientes/novo',
    name: 'clientes.create',
    meta: { title: 'Cliente | Novo' },
    component: () => import('@/views/clientes/ClientesForm.vue'),
  },
  {
    path: '/pesquisar-prospeccao',
    name: 'pesquisa.prospeccao',
    meta: { title: 'Prospecção' },
    component: () => import('@/views/clientes/PesquisaProspeccao.vue'),
  },

  {
    path: '/clientes/:uuid/editar',
    name: 'clientes.update',
    component: () => import('@/views/clientes/ClientesForm.vue'),
  },
]

export default clientes
