const erpBeneficios = [
  {
    path: '/beneficios',
    name: 'beneficios.list',
    meta: { title: 'ERP | Benefícios' },
    component: () => import('@/views/erpbeneficios/ErpBeneficiosList.vue'),
  },
]

export default erpBeneficios
