const projetos = [
  {
    path: '/projetos',
    name: 'projetos.list',
    meta: { title: 'Projetos' },
    component: () => import('@/views/projetos/ProjetosList.vue'),
  },
  {
    path: '/projetos/novo',
    name: 'projetos.new',
    meta: { title: 'Projetos | Novo' },
    component: () => import('@/views/projetos/ProjetosForm.vue'),
  },

  {
    path: '/projetos/:id/editar',
    name: 'projetos.editar',
    component: () => import('@/views/projetos/ProjetosForm.vue'),
  },
]

export default projetos
