export default {
    namespaced: true,
    state: {
        clientes: {},
    },

    mutations: {
        // eslint-disable-next-line no-shadow
        SET_CLIENTES(state, payload) {
            state.clientes = payload
        },
    },
    actions: {
        getClientes: async ({commit, state}, status) => {
            try {

                let statusCache = status
                if (!statusCache) {
                    statusCache = 'all'
                }
                if (state.clientes[statusCache]?.length > 0) {
                    return state.clientes[status]
                }
                let search = ''
                if (status) {
                    search = `&search=situacao:${status}`
                }
                const data = await window.http.get('/clientes?fields=razao_social,apelido,cnpj,uuid,id,suporte_bloqueado' + search, {loading: false})
                state.clientes[status] = data
                return data
            } catch (error) {
                return error
            }
        },
        getClientesAtivos: async ({commit, state}, status) => {
            try {

                let statusCache = status
                if (!statusCache) {
                    statusCache = 'all'
                }
                if (state.clientes[statusCache]?.length > 0) {
                    return state.clientes[status]
                }
                let search = ''
                if (status) {
                    search = `&search=situacao:${status}`
                }
                const data = await window.http.get('/clientes-ativos?fields=razao_social,apelido,cnpj,uuid,id,suporte_bloqueado' + search, {loading: false})
                state.clientes[status] = data
                return data
            } catch (error) {
                return error
            }
        },
        getClientesNovo: async ({commit, state}, status) => {
            try {

                let statusCache = status
                if (!statusCache) {
                    statusCache = 'all'
                }
                if (state.clientes[statusCache]?.length > 0) {
                    return state.clientes[status]
                }
                let search = ''
                let endpointCliente = '/clientes'

                if (status) {
                    if (status === 'matriz') {
                        endpointCliente = '/clientes-ativos'
                    } else {
                        search = `&search=situacao:${status}`
                    }
                }
                const data = await window.http.get(endpointCliente + '?fields=razao_social,apelido,cnpj,uuid,id,suporte_bloqueado' + search, {loading: false})
                state.clientes[status] = data
                return data
            } catch (error) {
                return error
            }
        },
    },
}
