const redes = [
  {
    path: '/redes',
    name: 'redes.list',
    meta: { title: 'Redes Associativas' },
    component: () => import('@/views/redes/RedesList.vue'),
  },
]

export default redes
