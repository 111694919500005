const erpErros = [
  {
    path: '/erp-erros',
    name: 'erp-erros.list',
    meta: { title: 'ERP | Erros' },
    component: () => import('@/views/erperros/ErpErrosList.vue'),
  },
]

export default erpErros
