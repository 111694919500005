<template>
  <validation-provider
      v-slot="{ errors }"
      :name="label"
      :vid="name"
      :rules="rules"
  >
    <b-form-group
        :label="label"
        :label-for="name"
    >
      <v-select
          key="value"
          ref="select"
          :id="name"
          v-model="inputValue"
          :name="name"
          :options="options"
          :placeholder="placeholder"
          label="text"
          v-bind="$attrs"
          :state="errors.length > 0 ? false:null"
          :disabled="disabled"
          :multiple="multiple"
          @open="onOpen"
      >
        <div slot="no-options">
          Nenhum(a) {{ label }} encontrado(a).
        </div>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>

import {ValidationProvider} from 'vee-validate'
import {required} from '@validations'

export default {
  name: 'I2FormVueSelect',
  components: {
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Object, Array],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    rules: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      inputValue: '',
      selected: null,
      required,
      zIndex:9999,
    }
  },
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
      this.$emit('onChange', val)
    },
  },
  mounted() {
    this.inputValue = this.value
  },
  methods: {
    setFocus() {
      this.$refs.select.open = true
      this.$refs.select.searchEl.focus()
    },
    onOpen() {
      setTimeout(() => {
        // Acesse o dropdown diretamente com o ref e modifique o estilo
        const dropdownMenu = this.$refs.select.$el.querySelector('.vs__dropdown-menu')
        if (dropdownMenu) {
          this.zIndex =  50000
          dropdownMenu.style.zIndex = '500000 !important';  // Ajuste o z-index
          dropdownMenu.style.borderColor = 'red !important';  // Ajuste o z-index
          dropdownMenu.style.position = 'absolute !important';  // Ajuste o z-index
        }
      }, 200)
    },
  },

}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
.vs__dropdown-menu {
  z-index:99999 !important;
}
</style>
