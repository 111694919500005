export default {
    namespaced: true,
    state: {
        atendimento: [],
        atendimento_original: {},
        etapa_original: {},
    },
    getters: {
        atendimento: state => state.atendimento,
        atendimento_original: state => state.atendimento_original,
        etapa_original: state => state.etapa_original,
    },
    mutations: {
        // eslint-disable-next-line no-shadow
        SET_ATENDIMENTO(state, payload) {
            state.atendimento = payload
        },
        SET_ATENDIMENTO_ORIGINAL(state, payload) {
            state.atendimento_original = payload
        },
        SET_ETAPA_ORIGINAL(state, payload) {
            state.etapa_original = payload.etapas.uuid
        },
    },
    actions: {
        getAtendimento: async ({ commit }, id) => {
            try {
                const data = await window.http.get(`atendimentos/${id}`)
                commit('SET_ATENDIMENTO', data)
                commit('SET_ATENDIMENTO_ORIGINAL', data)
                commit('SET_ETAPA_ORIGINAL', data)
                return data
            } catch (error) {
                return error
            }
        },
        setAtendimento: ({ commit }, atendimento) => {
            commit('SET_ATENDIMENTO', atendimento)
        }
    },

}
