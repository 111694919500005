const atendimentosComercial = [
  {
    path: '/atendimentos-comercials',
    name: 'atendimentos-comercials.list',
    component: () => import('@/views/atendimentoscomercial/AtendimentosComercialList.vue'),
  },
  {
    path: '/comercial/comissoes',
    name: 'comercial.comissoes',
    component: () => import('@/views/atendimentoscomercial/AtendimentosComercialList.vue'),
  },
  {
    path: '/atendimento-comercial/:uuid/aprovar',
    name: 'atendimentos-comercials.aprovar',
    component: () => import('@/views/atendimentoscomercial/AtendimentosComercialAprovar.vue'),
    meta: {
      layout: 'full',
      public:true
    },
  },
  {
    path: '/atendimento-comercial/:uuid/rejeitar',
    name: 'atendimentos-comercials.rejeitar',
    component: () => import('@/views/atendimentoscomercial/AtendimentosComercialReprovar.vue'),
    meta: {
      layout: 'full',
      public:true
    },
  }
]

export default atendimentosComercial
