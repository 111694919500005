const releases = [
  {
    path: '/releases',
    name: 'releases.list',
    meta: { title: 'Releases' },
    component: () => import('@/views/releases/ReleasesList.vue'),
  },
  {
    path: '/releases-atendimentos',
    name: 'releases.atendimentos.list',
    meta: { title: 'Releases' },
    component: () => import('@/views/atendimentos/AtendimentosReleaseList.vue'),
  },
]

export default releases
