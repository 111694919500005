const modulos = [
  {
    path: '/modulos',
    name: 'modulos.list',
    meta: { title: 'Módulos' },
    component: () => import('@/views/modulos/ModulosList.vue'),
  },
]

export default modulos
