const acompanhamentos = [
  {
    path: '/acompanhamentos',
    name: 'acompanhamentos.list',
    meta: { title: 'Comentários' },
    component: () => import('@/views/acompanhamentos/AcompanhamentosList.vue'),
  },
]

export default acompanhamentos
