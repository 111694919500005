const erpCests = [
  {
    path: '/cests',
    name: 'cests.list',
    meta: { title: 'ERP | CESTs' },
    component: () => import('@/views/erpcests/ErpCestsList.vue'),
  },
]

export default erpCests
