<template>
    <b-form-group
            :label="label"
            :label-for="name"
    >
        <validation-provider
                v-slot="{ errors }"
                :name="label"
                :vid="name"
                :rules="rules"
        >
            <money
                    ref="inputRef"
                    v-model.lazy="inputValue"
                    v-bind="money"
                    class="form-control text-right"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="placeholder"
                    :readonly="readonly"
                    :disabled="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
    </b-form-group>

</template>
<script>
import { ValidationProvider } from 'vee-validate'
import {
    min, minValue, required,
} from '@core/utils/validations/validations'

export default {
    name: 'I2FormInputMoney',
    components: {
        ValidationProvider,
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        options: Object,
        value: {
            type: [String, Number],
            default: 0,
        },
        label: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        rules: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        mask: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        delay: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            inputValue: '',
            errors: [],
            required,
            min,
            minValue,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false,
            },
        }
    },
    watch: {
        value(data) {
            this.inputValue = data
        },
        inputValue(val) {
            this.$emit('input', val)
        },
    },
    created() {
        this.inputValue = this.value
    },
}
</script>
