const produtos = [
  {
    path: '/produtos',
    name: 'produtos.list',
    meta: { title: 'Produtos' },
    component: () => import('@/views/produtos/ProdutosList.vue'),
  },
]

export default produtos
