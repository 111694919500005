import Vue from 'vue'
import moment from "moment/moment";

Vue.filter('maskHHMM', function (minutes) {

        const momentObj = moment.duration(minutes, 'minutes');
        const days = momentObj.days();
        const hours = momentObj.hours() + (days * 24);
        const minutesPart = momentObj.minutes();

        return `${hours}:${minutesPart.toString().padStart(2, '0')}`;

    }
)
