import Vue, {getCurrentInstance} from 'vue'

const ParseQuerySearch = {

    parseQuerySearch(data) {
        const vm = getCurrentInstance()
        if (!vm.proxy.$route.query.search) {
            return
        }
        const query = vm.proxy.$route.query.search.split(';')
        query.forEach(condition => {

            const [field, operator, value] = condition.split(':')
            for (const key in data) {

                if (key === 'search' && field === 'search') {
                    const parts = condition.split(':')
                    data[key] = parts[1] || ''
                    break
                }
                if (data[key].field === field && field !== 'search') {
                    let val = value.includes('|') ? value.split('|') : [value]
                    if (typeof(data[key].value) === "string") {
                        val = value
                    }
                    data[key].value = val
                    break
                }
            }
        })
    },
    getCurrentPage() {
        if (!this.$route.query.page) {
            return {
                page: 1,
                per_page: 25,
            }
        }

        return {
            page: this.$route.query.page || 1,
            per_page: this.$route.query.per_page || 25,
        }
    },
}
Vue.prototype.$parseQuery = ParseQuerySearch
window.parseQuery = ParseQuerySearch
export default ParseQuerySearch
