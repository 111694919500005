const erpNcms = [
  {
    path: '/ncms',
    name: 'ncms.list',
    meta: { title: 'ERP | NCMs' },
    component: () => import('@/views/erpncms/ErpNcmsList.vue'),
  },

  {
    path: '/ncms/:id/editar',
    name: 'ncms.edit',
    meta: { title: 'ERP | NCMs' },
    component: () => import('@/views/erpncms/ErpNcmsForm.vue'),
  },
  {
    path: '/ncms/novo',
    name: 'ncms.new',
    meta: { title: 'ERP | NCMs' },
    component: () => import('@/views/erpncms/ErpNcmsForm.vue'),
  },
]

export default erpNcms
