const dashboards = [
    {
        path: '/dashboards/geral',
        name: 'dashboard.geral',
        meta: { title: 'Dashboard | Geral' },
        component: () => import('@/views/dashboards/DashboardGeral.vue'),
    },
    {
        path: '/dashboards/suporte',
        name: 'dashboard.suporte',
        meta: { title: 'Dashboard | Suporte' },
        component: () => import('@/views/dashboards/DashboardSuporte.vue'),
    },
    {
        path: '/dashboards/infraestrutura',
        name: 'dashboard.infraestrutura',
        meta: { title: 'Dashboard | Infra' },
        component: () => import('@/views/dashboards/DashboardInfraestrutura.vue'),
    },
    {
        path: '/dashboards/desenvolvimento',
        name: 'dashboard.desenvolvimento',
        meta: { title: 'Dashboard | Desenvolvimento' },
        component: () => import('@/views/dashboards/DashboardDesenvolvimento.vue'),
    },
    {
        path: '/dashboards/releases',
        name: 'dashboard.releases',
        meta: { title: 'Dashboard | Releases' },
        component: () => import('@/views/dashboards/DashboardDesenvolvimento.vue'),
    },
    {
        path: '/dashboards/cliente',
        name: 'dashboard.cliente',
        meta: { title: 'Dashboard | Cliente' },
        component: () => import('@/views/dashboards/DashboardDesenvolvimento.vue'),
    },
    {
        path: '/dashboards/resumo-cliente',
        name: 'dashboard.resumo-cliente',
        meta: { title: 'Dashboard | Clientes' },
        component: () => import('@/views/dashboards/DashboardResumoCliente.vue'),
    },
    {
        path: '/dashboards/produtividade',
        name: 'dashboard.produtividade',
        meta: { title: 'Dashboard | Produtividade' },
        component: () => import('@/views/dashboards/DashboardProdutividade.vue'),
    },
    {
        path: '/dashboards/top-clientes',
        name: 'dashboard.topclientes',
        meta: { title: 'Dashboard | top Clientes' },
        component: () => import('@/views/dashboards/DashboardTopClientes.vue'),
    },

]

export default dashboards
