const state = {
    modulos: null,
}

const getters = {

}

const mutations = {
SET_MODULOS(state, payload){
    state.modulos = payload
},
}

const actions = {
    getModulos: async ({commit, state},search) => {
        if(state.modulos){
            return state.modulos
        }

        try
        {
            const data =  await window.http.get(`/modulos?search=${search}`, {loading: false})
            commit('SET_MODULOS',data)
            return data
        } catch (error){
            console.log(error)
            return error
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
